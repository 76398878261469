var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"bg-input",attrs:{"id":"bg-input"}},[(!_vm.supportsUpload)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.strings.upload)}}):(_vm.supportsPreview)?_c('div',[_c('div',{staticClass:"preview-container",style:({
        maxWidth: _vm.previewWidth + 'px',
        height: _vm.previewHeight + 'px',
        borderRadius: _vm.radius + '%',
      })},[_c('canvas',{ref:"previewCanvas",staticClass:"picture-preview",class:_vm.computedClasses,style:({ height: _vm.previewHeight + 'px', zIndex: _vm.zIndex + 1 }),on:{"drag":function($event){$event.stopPropagation();$event.preventDefault();},"dragover":function($event){$event.stopPropagation();$event.preventDefault();},"dragstart":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStart($event)},"dragenter":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStart($event)},"dragend":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStop($event)},"dragleave":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDragStop($event)},"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onFileDrop($event)},"click":function($event){$event.preventDefault();return _vm.onClick($event)}}}),(!_vm.imageSelected && !_vm.plain)?_c('div',{staticClass:"picture-inner",style:({
          top: -_vm.previewHeight + 'px',
          marginBottom: -_vm.previewHeight + 'px',
          fontSize: _vm.fontSize,
          borderRadius: _vm.radius + '%',
          zIndex: _vm.zIndex + 2,
        })},[(_vm.supportsDragAndDrop)?_c('span',{staticClass:"picture-inner-text",domProps:{"innerHTML":_vm._s(_vm.strings.drag)}}):_c('span',{staticClass:"picture-inner-text",domProps:{"innerHTML":_vm._s(_vm.strings.tap)}})]):_vm._e()]),(_vm.imageSelected && _vm.removable)?_c('button',{class:_vm.removeButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.removeImage($event)}}},[_vm._v(" "+_vm._s(_vm.strings.remove)+" ")]):_vm._e(),(_vm.imageSelected && _vm.toggleAspectRatio && _vm.width !== _vm.height)?_c('button',{class:_vm.aspectButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.rotateImage($event)}}},[_vm._v(" "+_vm._s(_vm.strings.aspect)+" ")]):_vm._e()]):_c('div',[(!_vm.imageSelected)?_c('button',{class:_vm.buttonClass,on:{"click":function($event){$event.preventDefault();return _vm.selectImage($event)}}},[_vm._v(" "+_vm._s(_vm.strings.select)+" ")]):_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.strings.selected)}}),_c('button',{class:_vm.buttonClass,on:{"click":function($event){$event.preventDefault();return _vm.selectImage($event)}}},[_vm._v(" "+_vm._s(_vm.strings.change)+" ")]),(_vm.removable)?_c('button',{class:_vm.removeButtonClass,on:{"click":function($event){$event.preventDefault();return _vm.removeImage($event)}}},[_vm._v(" "+_vm._s(_vm.strings.remove)+" ")]):_vm._e()])]),_c('input',{ref:"fileInput",attrs:{"type":"file","name":_vm.name,"id":_vm.id,"accept":_vm.accept},on:{"change":_vm.onFileChange}})])}
var staticRenderFns = []

export { render, staticRenderFns }