<template>
  <div style="position: relative">
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-img :src="flag_selected" width="24" />
      </b-input-group-prepend>
      <b-form-input
        type="text"
        v-model="keyword"
        autocomplete="false"
        @input="updateValue"
        @keyup.native.esc="esc"
        @blur.native="blur"
        @keydown.native.enter="enter"
        @keydown.native.down="down"
        @keydown.native.up="up"
        style="font-size: 1rem"
      >
      </b-form-input>
    </b-input-group>
    <b-list-group v-show="open">
      <b-list-group-item
        v-for="(suggestion, index) in matches"
        v-bind:class="{ active: isActive(index) }"
        @mousedown="suggestionClick(index)"
        href="#"
        :key="index"
      >
        <b-img-lazy
          :src="`${publicPath}assets/images/flags/24/${
            suggestion.sigla ? suggestion.sigla : '_unknown'
          }.png`"
          width="24"
          class="mr-1"
        />
        {{ suggestion.paese }}</b-list-group-item
      >
    </b-list-group>
  </div>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    row_index: {
      type: Number,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      open: false,
      current: 0,
      id_paese: 0,
      keyword: "",
    };
  },
  computed: {
    // Filtering the suggestion based on the input
    matches() {
      const re = new RegExp(this.keyword, "i");
      return this.suggestions.filter((obj) => obj.paese.match(re));
    },
    flag_selected: function () {
      if (this.id_paese > 0) {
        var sigla = "";
        for (var index in this.suggestions) {
          var item = this.suggestions[index];
          if (this.id_paese == item.id) {
            sigla = item.sigla;
          }
        }

        if (sigla) {
          return `${
            this.publicPath
          }assets/images/flags/24/${sigla.toUpperCase()}.png`;
        }
      }
      return `${this.publicPath}assets/images/flags/24/_unknown.png`;
    },
    check_valid_paese: function () {
      if (this.id_paese > 0) {
        for (var index in this.suggestions) {
          var item = this.suggestions[index];
          if (this.id_paese == item.id) {
            if (item.paese == this.keyword) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    updateValue() {
      if (this.check_valid_paese) {
        return;
      }
      this.open = true;
      this.current = 0;
      this.id_paese = 0;
      this.$emit("change", this.row_index);
    },
    esc() {
      this.open = false;
    },
    blur() {
      this.open = false;
    },
    // When enter pressed on the input
    enter() {
      this.id_paese = this.matches[this.current].id;
      //  this.$emit('select', this.id_paese)
      this.$emit("select", this.id_paese, this.row_index);
      this.open = false;
      this.keyword = this.matches[this.current].paese;
    },
    // When up pressed while suggestions are open
    up() {
      if (this.current > 0) {
        this.current--;
      }
    },
    // When up pressed while suggestions are open
    down() {
      if (this.current < this.matches.length - 1) {
        this.current++;
      }
    },
    // For highlighting element
    isActive(index) {
      return index === this.current;
    },
    // When one of the suggestion is clicked
    suggestionClick(index) {
      this.id_paese = this.matches[index].id;
      this.$emit("select", this.id_paese, this.row_index);
      this.open = false;
      this.keyword = this.matches[index].paese;
    },
    setPaese(id, nome) {
      this.id_paese = id;
      this.$emit("select", id, this.row_index);
      this.open = false;
      this.keyword = nome;
    },
  },
};
</script>
